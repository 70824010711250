import SplitType from 'split-type'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const revealText = (el) => {
  const duration = 0.8
  const splittingTitle = new SplitType(el, { types: 'words, lines' })

  gsap.set(el.querySelectorAll('.line'), {
    overflow: 'hidden',
  })
  gsap.set(el.querySelectorAll('.word'), {
    y: '110%',
    skewY: 7,
  })

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'bottom bottom ',
    },
    onComplete: () => {
      tl.kill()
    },
  })
  tl.to(splittingTitle.words, {
    y: '0%',
    skewY: 0,
    ease: 'power4.out',
    stagger: 0.15,
    duration,
  })
}
