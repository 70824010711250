import gql from 'graphql-tag'

export const craftState = gql`
  query CraftState($site: [String]) {
    entries(
      site: $site
      section: ["typologies", "markets", "lines", "categories"]
    ) {
      ... on typologies_default_Entry {
        id
        key
        slug
        sectionHandle
      }
      ... on markets_market_Entry {
        id
        key
        slug
        sectionHandle
      }
      ... on lines_line_Entry {
        id
        key
        slug
        sectionHandle
      }
      ... on categories_category_Entry {
        id
        key
        slug
        sectionHandle
      }
    }
    globalSet(handle: "companyInfo") {
      ... on companyInfo_GlobalSet {
        companyAddresses {
          ... on companyAddresses_companyAddress_BlockType {
            id
            companyAddressContent
            companyAddressType
            companyAddressMeta
          }
        }
      }
    }
  }
`
