import gql from 'graphql-tag'

export const listingDesignerFragment = gql`
  fragment ListingDesignerFragment on designers_designers_Entry {
    slug
    id
    title
    designerImage @transform(format: "webp", width: 1600, height: 2100) {
      id
      srcset(sizes: ["750w", "1600w"])
      url
      width
      height
      title
    }
  }
`

export const designerFragment = gql`
  fragment DesignerFragment on designers_designers_Entry {
    slug
    id
    designerDescription
    title
    designerImage @transform(format: "webp", width: 1600, height: 2100) {
      id
      srcset(sizes: ["750w", "1600w"])
      url
      width
      height
      title
    }
    localized {
      slug
      language
    }
  }
`
