import gql from 'graphql-tag'
import {
  listingFamilyFragment,
  listingProductFragment,
} from '../fragments/products.js'
import { seoFragment } from '../fragments/seo.js'

export const getTypologyIds = gql`
  query GetTypologyIds(
    $site: [String]
    $marketId: [QueryArgument]
    $typologyId: [QueryArgument]
    $type: [String]
    $id: [QueryArgument]
    $categoryId: [QueryArgument]
    $lineId: [QueryArgument]
    $limit: Int = 200
    $offset: Int = 0
  ) {
    productsEntries(
      site: $site
      market: $marketId
      typology: $typologyId
      type: $type
      id: $id
      line: $lineId
      limit: $limit
      offset: $offset
    ) {
      ... on products_family_Entry {
        id
        slug
        children(relatedCategories: $categoryId) {
          ... on products_product_Entry {
            id
            slug
          }
        }
      }
    }
    entryCount(
      site: $site
      market: $marketId
      typology: $typologyId
      type: $type
      line: $lineId
    )
  }
`

export const getTypology = gql`
  ${listingFamilyFragment}
  ${listingProductFragment}
  ${seoFragment}
  query GetTypology(
    $site: [String]
    $marketId: [QueryArgument]
    $typologyId: [QueryArgument]
    $typologyKey: [QueryArgument]
    $type: [String]
    $id: [QueryArgument]
    $categoryId: [QueryArgument]
    $lineId: [QueryArgument]
    $limit: Int = 200
    $offset: Int = 0
  ) {
    productsEntries(
      site: $site
      market: $marketId
      typology: $typologyId
      type: $type
      id: $id
      line: $lineId
      limit: $limit
      offset: $offset
    ) {
      ... on products_family_Entry {
        ...ListingFamilyFragment
        children(relatedCategories: $categoryId) {
          ... on products_product_Entry {
            ...ListingProductFragment
          }
        }
      }
    }
    entryCount(
      site: $site
      market: $marketId
      typology: $typologyId
      id: $id
      type: $type
      line: $lineId
    )
    entry(key: $typologyKey, site: $site) {
      seo {
        ...SeoFragment
      }
    }
  }
`

export const getFilters = gql`
  query GetFilters($site: [String], $id: [QueryArgument]) {
    categoriesEntries(site: $site, relatedToEntries: { id: $id }) {
      ... on categories_category_Entry {
        id
        title
        slug
      }
    }
  }
`
