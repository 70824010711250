import gql from 'graphql-tag'

export const editorialContentText = gql`
  fragment EditorialContentText on editorialContent_editorialContent_BlockType {
    id
    text
  }
`

export const editorialContentFullScreenGallery = gql`
  fragment EditorialContentFullScreenGallery on editorialContent_editorialContentFullScreenGallery_BlockType {
    id
    fullscreen
    gallery {
      id
      title
    }
    landscape: gallery @transform(format: "webp", width: 1920, height: 1080) {
      id
      srcset(sizes: ["750w", "1300w", "1920w"])
      url
      width
      height
      title
    }
    portrait: gallery @transform(format: "webp", width: 1000, height: 1500) {
      id
      srcset(sizes: ["750w", "1000w"])
      url
      width
      height
      title
    }
  }
`

export const editorialContentGallery = gql`
  fragment EditorialContentGallery on editorialContent_editorialContentGallery_BlockType {
    id
    gallery @transform(format: "webp", width: 1920, height: 1080) {
      id
      srcset(sizes: ["750w", "1300w", "1920w"])
      url
      width
      height
      title
    }
  }
`

export const editorialContentVideo = gql`
  fragment EditorialContentVideo on editorialContent_editorialContentVideo_BlockType {
    id
    video
    fullscreen
    embed
    autoplay
  }
`

export const editorialContentDoubleColumnImages = gql`
  fragment EditorialContentDoubleColumnImages on editorialContent_editorialContentDoubleColumnImages_BlockType {
    id
    doubleColumn
    images @transform(format: "webp", width: 1000, height: 1500) {
      id
      srcset(sizes: ["750w", "1000w"])
      url
      width
      height
      title
    }
  }
`

export const editorialContentBigTitle = gql`
  fragment EditorialContentBigTitle on editorialContent_editorialContentBigTitle_BlockType {
    id
    text
  }
`

export const editorialContentDoubleColumnGeneral = gql`
  fragment EditorialContentDoubleColumnGeneral on editorialContent_editorialContentDoubleColumnGeneral_BlockType {
    id
    typeHandle
    doubleColumn
    contentText1
    contentText2
  }
`

export const editorialContentFreeContent = gql`
  fragment EditorialContentFreeContent on editorialContent_editorialContentFreeContent_BlockType {
    id
    typeHandle
    freeContent
  }
`
