import { revealText } from '~/plugins/animation-directives/reveal-text.js'
import { parallaxElement } from '~/plugins/animation-directives/parallax-element.js'
import { scrollSpeed } from '~/plugins/animation-directives/scroll-speed.js'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default defineNuxtPlugin((nuxtApp) => {
  // Reveal Text
  nuxtApp.vueApp.directive('anim-reveal-text', {
    mounted(el) {
      revealText(el)
    },
  })

  // Scroll Speed
  nuxtApp.vueApp.directive('anim-scroll-speed', {
    mounted(el, binding) {
      scrollSpeed(el, binding)
      //TODO: Non è bellissima come soluzione ma serve per fare un refresh dopo i caricamenti dei vari elementi della pagina
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 500)
    },
  })

  // Parallax Image
  nuxtApp.vueApp.directive('anim-parallax-element', {
    mounted(el, binding) {
      parallaxElement(el, binding)
      //TODO: Non è bellissima come soluzione ma serve per fare un refresh dopo i caricamenti dei vari elementi della pagina
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 500)
    },
  })
})
