import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import { listingFamilyFragment } from '../fragments/products.js'
import { listingNewsFragment } from '../fragments/news.js'

export const getHome = gql`
  ${seoFragment}
  ${listingFamilyFragment}
  ${listingNewsFragment}
  query GetHome($marketId: [QueryArgument], $site: [String]) {
    homeEntries(site: $site) {
      id
      ... on home_home_Entry {
        id
        homeHeader {
          ... on homeHeader_homeHeader_BlockType {
            id
            homeHeaderImage {
              id
              title
            }
            landscape: homeHeaderImage
              @transform(format: "webp", width: 3840, height: 2160) {
              id
              srcset(sizes: ["750w", "1300w", "1920w", "3840w"])
              url
              width
              height
              title
            }
            portrait: homeHeaderImage
              @transform(format: "webp", width: 1000, height: 1500) {
              id
              srcset(sizes: ["750w", "1000w"])
              url
              width
              height
              title
            }
            mobile: homeHeaderImageMobile
              @transform(format: "webp", width: 1000, height: 1500) {
              id
              srcset(sizes: ["750w", "1000w"])
              url
              width
              height
              title
            }
            homeHeaderTitle
            homeHeaderVideoUrl
          }
        }
        homeHighlightsContent {
          ... on homeHighlightsContent_homeHighlightsContent_BlockType {
            id
            homeHighlightsContentText
            homeHighlightsContentTitle
            homeHighlightsContentProductUsa(market: $marketId) {
              ... on products_family_Entry {
                ...ListingFamilyFragment
              }
            }
            homeHighlightsContentProductGlobal(market: $marketId) {
              ... on products_family_Entry {
                ...ListingFamilyFragment
              }
            }
          }
        }
        homeProjects {
          ... on projects_projects_Entry {
            id
            slug
            title
            cover: projectCover
              @transform(format: "webp", width: 1600, height: 900) {
              id
              srcset(sizes: ["750w", "1600w"])
              url
              width
              height
              title
            }
          }
        }
        homeBanner {
          ... on homeBanner_homeBanner_BlockType {
            id
            homeBannerImage {
              id
              title
            }
            landscape: homeBannerImage
              @transform(format: "webp", width: 3840, height: 2160) {
              id
              srcset(sizes: ["750w", "1300w", "1920w"])
              url
              width
              height
              title
            }
            portrait: homeBannerImage
              @transform(format: "webp", width: 1000, height: 1500) {
              id
              srcset(sizes: ["750w", "1000w"])
              url
              width
              height
              title
            }
            homeBannerTitle
            homeBannerText
            homeBannerVideoUrl
          }
        }
      }
      seo {
        ...SeoFragment
      }
    }
    newsEntries(site: $site, limit: 3, market: $marketId) {
      ... on news_news_Entry {
        ...ListingNewsFragment
      }
    }
  }
`
