import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import {
  listingBespokeProjectsFragment,
  bespokeProjectsFragment,
} from '../fragments/bespoke.js'
import {
  editorialContentDoubleColumnImages,
  editorialContentVideo,
  editorialContentGallery,
  editorialContentFullScreenGallery,
  editorialContentText,
  editorialContentBigTitle,
  editorialContentDoubleColumnGeneral,
  editorialContentFreeContent,
} from '../fragments/editorial.js'

export const getBespokeProjects = gql`
  ${listingBespokeProjectsFragment}
  ${seoFragment}
  query GetBespokeProjects(
    $site: [String]
    $limit: Int = 100
    $offset: Int = 0
    $projectType: [QueryArgument]
  ) {
    bespokeEntries(
      site: $site
      projectType: $projectType
      limit: $limit
      offset: $offset
    ) {
      ... on bespoke_bespoke_Entry {
        ...ListingBespokeProjectsFragment
      }
    }
    entryCount(site: $site, projectType: $projectType, section: "bespoke")
    bespokeHomeEntries(site: $site) {
      ... on bespokeHome_bespokeHome_Entry {
        id
        generalHeader {
          ... on generalHeader_generalHeader_BlockType {
            id
            generalHeaderText
            generalHeaderTitle
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`

export const getBespokeProject = gql`
  ${listingBespokeProjectsFragment}
  ${bespokeProjectsFragment}
  ${seoFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  query GetBespokeProject($site: [String], $projectSlug: [String]) {
    bespokeEntries(site: $site, slug: $projectSlug) {
      ... on bespoke_bespoke_Entry {
        ...BespokeProjectsFragment
      }
      editorialContent {
        id
        typeHandle
        ...EditorialContentText
        ...EditorialContentFullScreenGallery
        ...EditorialContentGallery
        ...EditorialContentVideo
        ...EditorialContentBigTitle
        ...EditorialContentDoubleColumnImages
        ...EditorialContentDoubleColumnGeneral
        ...EditorialContentFreeContent
      }
      seo {
        ...SeoFragment
      }
    }
    latest: bespokeEntries(site: $site, limit: 6) {
      ... on bespoke_bespoke_Entry {
        ...ListingBespokeProjectsFragment
      }
    }
  }
`
