import revive_payload_client_30soggh6DJ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4ylI0N3ydh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2PNHY6uaUf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_VazWVox23X from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.26.0_vite@5.4.11_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ID95EvvE9N from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RuEkGdy21L from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5whsZAvbrG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nF4lCM84ph from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4aDm6lOeVV from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.26.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_pkMxhPWPfX from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@20.17.6_eslint@8.57.1_rollup@4.26.0_sass@1.80.7_stylelint@14.16.1_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_H0RYyX5u9k from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.14.159_rollup@4.26.0_vue@3.5.12/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_clyVitzQ8z from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.26.0_typescript@5.6.3_vue@3.5.12/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_EjR3ipLBGx from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.26.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ObTBmNDEqR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.26.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import leaflet_client_trb2AG60Fs from "/opt/build/repo/plugins/leaflet.client.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_30soggh6DJ,
  unhead_4ylI0N3ydh,
  router_2PNHY6uaUf,
  _0_siteConfig_VazWVox23X,
  payload_client_ID95EvvE9N,
  navigation_repaint_client_RuEkGdy21L,
  check_outdated_build_client_5whsZAvbrG,
  chunk_reload_client_nF4lCM84ph,
  plugin_vue3_4aDm6lOeVV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pkMxhPWPfX,
  plugin_H0RYyX5u9k,
  plugin_clyVitzQ8z,
  switch_locale_path_ssr_EjR3ipLBGx,
  i18n_ObTBmNDEqR,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  leaflet_client_trb2AG60Fs,
  sentry_client_GoGQuZo4Et
]