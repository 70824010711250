import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'

export const getContacts = gql`
  ${seoFragment}
  query GetContacts($site: [String]) {
    contactsEntries(site: $site) {
      ... on contacts_contacts_Entry {
        id
        subpageSeo {
          ... on subpageSeo_subpageSeo_BlockType {
            id
            subpageSeoMeta
            subpageSeoContent {
              ...SeoFragment
            }
          }
        }
      }
    }
  }
`
