import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import {
  listingProjectsFragment,
  projectsFragment,
} from '../fragments/projects.js'
import {
  editorialContentDoubleColumnImages,
  editorialContentVideo,
  editorialContentGallery,
  editorialContentFullScreenGallery,
  editorialContentText,
  editorialContentBigTitle,
  editorialContentDoubleColumnGeneral,
  editorialContentFreeContent,
} from '../fragments/editorial.js'

export const getProjects = gql`
  ${listingProjectsFragment}
  ${seoFragment}
  query getProjects(
    $site: [String]
    $limit: Int = 100
    $offset: Int = 0
    $projectType: [QueryArgument]
  ) {
    projectsEntries(
      site: $site
      projectType: $projectType
      limit: $limit
      offset: $offset
    ) {
      ... on projects_projects_Entry {
        ...ListingProjectsFragment
      }
    }
    entryCount(site: $site, projectType: $projectType, section: "projects")
    projectsHomeEntries(site: $site) {
      ... on projectsHome_projectsHome_Entry {
        id
        title
        seo {
          ...SeoFragment
        }
      }
    }
  }
`

export const getProject = gql`
  ${listingProjectsFragment}
  ${projectsFragment}
  ${seoFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  query GetProject($site: [String], $projectSlug: [String]) {
    projectsEntries(site: $site, slug: $projectSlug) {
      ... on projects_projects_Entry {
        ...ProjectsFragment
      }
      editorialContent {
        id
        typeHandle
        ...EditorialContentText
        ...EditorialContentFullScreenGallery
        ...EditorialContentGallery
        ...EditorialContentVideo
        ...EditorialContentBigTitle
        ...EditorialContentDoubleColumnImages
        ...EditorialContentDoubleColumnGeneral
        ...EditorialContentFreeContent
      }
      seo {
        ...SeoFragment
      }
    }
    latest: projectsEntries(site: $site, limit: 6) {
      ... on projects_projects_Entry {
        ...ListingProjectsFragment
      }
    }
  }
`
