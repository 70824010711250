import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import {
  listingFinishingFragment,
  finishingFragment,
} from '../fragments/finishings.js'
import { listingProductFragment } from '../fragments/products.js'

export const getFinishings = gql`
  ${listingFinishingFragment}
  ${seoFragment}
  query GetFinishings(
    $site: [String]
    $finishingColorType: [QueryArgument]
    $limit: Int = 100
    $offset: Int = 0
  ) {
    finishingsEntries(
      site: $site
      finishingColorType: $finishingColorType
      limit: $limit
      offset: $offset
    ) {
      ... on finishings_finish_Entry {
        ...ListingFinishingFragment
      }
    }
    entryCount(
      site: $site
      finishingColorType: $finishingColorType
      section: "finishings"
    )
    finishingsHomeEntries(site: $site) {
      ... on finishingsHome_finishingsHome_Entry {
        id
        generalHeader {
          ... on generalHeader_generalHeader_BlockType {
            id
            generalHeaderText
            generalHeaderTitle
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`

/* export const getFinishing = gql`
  query GetFinishing($slug: [String]) {
    finishingsEntries(site: "it", slug: $slug) {
      ... on finishings_finish_Entry {
        slug
        id
        finishingColorLabel
        seo {
          title
          description
        }
      }
    }
  }
` */

export const getFinishing = gql`
  ${finishingFragment}
  ${listingProductFragment}
  ${seoFragment}
  query GetFinishing(
    $site: [String]
    $limit: Int = 100
    $offset: Int = 0
    $slug: [String]
    $marketId: [QueryArgument]
  ) {
    finishingsEntries(site: $site, slug: $slug) {
      ... on finishings_finish_Entry {
        ...FinishingFragment
        seo {
          ...SeoFragment
        }
      }
    }
    productsEntries(
      site: $site
      market: $marketId
      relatedToEntries: { slug: $slug }
      limit: $limit
      offset: $offset
    ) {
      ... on products_product_Entry {
        ...ListingProductFragment
      }
    }
    entryCount(
      section: "products"
      site: $site
      market: $marketId
      relatedToEntries: { slug: $slug }
    )
  }
`
