import gql from 'graphql-tag'
import {
  listingFamilyFragment,
  familyFragment,
  listingProductFragment,
  productFragment,
} from '../fragments/products.js'
import { listingFinishingFragment } from '../fragments/finishings.js'
import { seoFragment } from '../fragments/seo.js'
import {
  editorialContentDoubleColumnImages,
  editorialContentVideo,
  editorialContentGallery,
  editorialContentFullScreenGallery,
  editorialContentText,
  editorialContentBigTitle,
  editorialContentDoubleColumnGeneral,
  editorialContentFreeContent,
} from '../fragments/editorial.js'

export const getFamily = gql`
  ${familyFragment}
  ${listingProductFragment}
  ${listingFinishingFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  ${seoFragment}
  query GetFamily(
    $site: [String]
    $marketId: [QueryArgument]
    $familySlug: [String]
  ) {
    productsEntries(site: $site, market: $marketId, slug: $familySlug) {
      ... on products_family_Entry {
        ...FamilyFragment
      }
      editorialContent {
        id
        typeHandle
        ...EditorialContentText
        ...EditorialContentFullScreenGallery
        ...EditorialContentGallery
        ...EditorialContentVideo
        ...EditorialContentBigTitle
        ...EditorialContentDoubleColumnImages
        ...EditorialContentDoubleColumnGeneral
        ...EditorialContentFreeContent
      }
      children(market: $marketId) {
        ... on products_product_Entry {
          ...ListingProductFragment
          relatedFinishing {
            ...ListingFinishingFragment
          }
          relatedInternalFinishing {
            ...ListingFinishingFragment
          }
          relatedWireFinishing {
            ...ListingFinishingFragment
          }
          relatedBaseFinishing {
            ...ListingFinishingFragment
          }
          relatedCategories {
            id
            slug
            title
          }
        }
      }
      seo {
        ...SeoFragment
      }
    }
  }
`

export const getProduct = gql`
  ${productFragment}
  ${listingFamilyFragment}
  ${listingProductFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  ${seoFragment}
  query GetProduct(
    $site: [String]
    $marketId: [QueryArgument]
    $productSlug: [String]
  ) {
    productsEntries(site: $site, market: $marketId, slug: $productSlug) {
      id
      title
      slug
      ... on products_product_Entry {
        ...ProductFragment
        productRecommended(market: $marketId) {
          ... on products_family_Entry {
            ...ListingFamilyFragment
          }
        }
        parent(market: $marketId) {
          ... on products_family_Entry {
            ...ListingFamilyFragment
          }
          children(market: $marketId) {
            ...ListingProductFragment
          }
        }

        editorialContent {
          id
          typeHandle
          ...EditorialContentText
          ...EditorialContentFullScreenGallery
          ...EditorialContentGallery
          ...EditorialContentVideo
          ...EditorialContentBigTitle
          ...EditorialContentDoubleColumnImages
          ...EditorialContentDoubleColumnGeneral
          ...EditorialContentFreeContent
        }
      }

      seo {
        ...SeoFragment
      }
    }
  }
`
