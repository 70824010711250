import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import { listingNewsFragment, newsFragment } from '../fragments/news.js'
import {
  editorialContentDoubleColumnImages,
  editorialContentVideo,
  editorialContentGallery,
  editorialContentFullScreenGallery,
  editorialContentText,
  editorialContentBigTitle,
  editorialContentDoubleColumnGeneral,
  editorialContentFreeContent,
} from '../fragments/editorial.js'

export const getNewsList = gql`
  ${listingNewsFragment}
  ${seoFragment}
  query GetNewsList(
    $site: [String]
    $limit: Int = 100
    $offset: Int = 0
    $newsType: [QueryArgument]
    $marketId: [QueryArgument]
  ) {
    newsEntries(
      site: $site
      market: $marketId
      newsType: $newsType
      limit: $limit
      offset: $offset
    ) {
      ... on news_news_Entry {
        ...ListingNewsFragment
      }
    }
    entryCount(
      site: $site
      market: $marketId
      newsType: $newsType
      section: "news"
    )
    newsHomeEntries(site: $site) {
      ... on newsHome_newsHome_Entry {
        id
        title
        seo {
          ...SeoFragment
        }
      }
    }
  }
`

export const getNews = gql`
  ${listingNewsFragment}
  ${newsFragment}
  ${seoFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  query GetNews(
    $site: [String]
    $newsSlug: [String]
    $marketId: [QueryArgument]
  ) {
    newsEntries(site: $site, market: $marketId, slug: $newsSlug) {
      ... on news_news_Entry {
        ...NewsFragment
      }
      editorialContent {
        id
        typeHandle
        ...EditorialContentText
        ...EditorialContentFullScreenGallery
        ...EditorialContentGallery
        ...EditorialContentVideo
        ...EditorialContentBigTitle
        ...EditorialContentDoubleColumnImages
        ...EditorialContentDoubleColumnGeneral
        ...EditorialContentFreeContent
      }
      seo {
        ...SeoFragment
      }
    }
    latest: newsEntries(limit: 4, site: $site, market: $marketId) {
      ... on news_news_Entry {
        ...ListingNewsFragment
      }
    }
  }
`
