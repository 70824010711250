import gql from 'graphql-tag'
import { listingProductFragment } from '../fragments/products.js'
import { seoFragment } from '../fragments/seo.js'

export const getCategories = gql`
  query GetCategories($site: [String]) {
    categoriesEntries(site: $site) {
      ... on categories_category_Entry {
        id
        slug
        title
      }
    }
  }
`
export const getCategory = gql`
  ${listingProductFragment}
  ${seoFragment}
  query GetCategory(
    $site: [String]
    $marketId: [QueryArgument]
    $categoryId: [QueryArgument]
    $type: [String]
    $limit: Int = 100
    $offset: Int = 0
  ) {
    productsEntries(
      site: $site
      market: $marketId
      relatedCategories: $categoryId
      type: $type
      limit: $limit
      offset: $offset
    ) {
      ... on products_product_Entry {
        ...ListingProductFragment
        parent(market: $marketId) {
          id
        }
      }
    }
    entryCount(
      site: $site
      market: $marketId
      relatedCategories: $categoryId
      type: $type
    )
    entry(site: $site, id: $categoryId) {
      title
      seo {
        ...SeoFragment
      }
      localized {
        slug
        language
      }
    }
  }
`
