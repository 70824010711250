import gql from 'graphql-tag'

export const listingProjectsFragment = gql`
  fragment ListingProjectsFragment on projects_projects_Entry {
    id
    slug
    title
    projectType
    postDate
    cover: projectCover @transform(format: "webp", width: 1000, height: 750) {
      id
      srcset(sizes: ["750", "1000"])
      url
      width
      height
      title
    }
  }
`

export const projectsFragment = gql`
  fragment ProjectsFragment on projects_projects_Entry {
    id
    slug
    title
    projectType
    cover: projectCover @transform(format: "webp", width: 1920) {
      id
      srcset(sizes: ["750w", "1300w", "1920w"])
      url
      width
      height
      title
    }
    localized {
      slug
      language
    }
  }
`
