import gql from 'graphql-tag'
import { listingFinishingFragment } from '../fragments/finishings.js'

export const listingFamilyFragment = gql`
  fragment ListingFamilyFragment on products_family_Entry {
    id
    slug
    title
    cover: productFamilyCover
      @transform(format: "webp", width: 1600, height: 2400) {
      id
      srcset(sizes: ["750w", "1600w"])
      url
      width
      height
      title
    }
  }
`

export const listingProductFragment = gql`
  fragment ListingProductFragment on products_product_Entry {
    id
    title
    slug
    relatedCategories {
      id
      slug
      title
    }
    cover: productCover @transform(format: "webp", width: 1000, height: 1500) {
      id
      srcset(sizes: ["750", "1000"])
      url
      width
      height
      title
    }
    coverPortrait: productCoverPortrait
      @transform(format: "webp", width: 1600, height: 2400) {
      id
      srcset(sizes: ["750w", "1600w"])
      url
      width
      height
      title
    }
  }
`

export const familyFragment = gql`
  fragment FamilyFragment on products_family_Entry {
    id
    title
    smallTitleOnMobile
    slug
    typology {
      id
      slug
    }
    market {
      slug
    }
    productFamilyDescription
    productFamilyCover @transform(format: "webp", width: 1920, height: 2400) {
      id
      srcset(sizes: ["750w", "1000w", "1300w", "1600w", "1920w"])
      url
      width
      height
      title
    }
    localized {
      slug
      language
    }
  }
`

export const productFragment = gql`
  ${listingFinishingFragment}
  fragment ProductFragment on products_product_Entry {
    productCover {
      id
      title
    }
    localized {
      slug
      language
    }
    market {
      slug
    }
    coverLandscape: productCover
      @transform(format: "webp", width: 1920, height: 1080) {
      id
      srcset(sizes: ["750w", "1300w", "1920w"])
      url
      width
      height
      title
    }
    coverPortrait: productCover
      @transform(format: "webp", width: 1600, height: 2400) {
      id
      srcset(sizes: ["750w", "1600w"])
      url
      width
      height
      title
    }
    coverMobile: productCoverMobile
      @transform(format: "webp", width: 1000, height: 1500) {
      id
      srcset(sizes: ["750w", "1000w"])
      url
      width
      height
      title
    }
    relatedCategories {
      ... on categories_category_Entry {
        id
        slug
        title
      }
    }
    relatedFinishing {
      ... on finishings_finish_Entry {
        ...ListingFinishingFragment
      }
    }
    relatedInternalFinishing {
      ... on finishings_finish_Entry {
        ...ListingFinishingFragment
      }
    }
    relatedWireFinishing {
      ... on finishings_finish_Entry {
        ...ListingFinishingFragment
      }
    }
    relatedBaseFinishing {
      ... on finishings_finish_Entry {
        ...ListingFinishingFragment
      }
    }
    designer {
      ... on designers_designers_Entry {
        id
        title
        slug
      }
    }
    productMaterial
    productCertifications(label: true)
    productDimerable(label: true)
    productSizes
    productSizesUsa
    productCeilingSize
    productCeilingSizeUsa
    productLightSource
    productLightSourceUsa

    productDownloadDisegnoTecnico
    productDownloadFile3d
    productDownloadFotometria
    productDownloadIstruzioniDiMontaggio
    productDownloadIstruzioniDiMontaggioUsa
    productDownloadSchedaTecnica
    productDownloadSchedaTecnicaUsa

    productDetailsMeta {
      ... on productDetailsMeta_productDetailsMeta_BlockType {
        id
        productDetailsMetaTitle
        productDetailsMetaContent
      }
    }
    productSpecificationImage @transform(format: "webp", width: 600) {
      id
      srcset(sizes: ["1x", "2x"])
      url
      width
      height
      title
    }
    productSpecificationImageUsa @transform(format: "webp", width: 600) {
      id
      srcset(sizes: ["1x", "2x"])
      url
      width
      height
      title
    }
  }
`
