import gql from 'graphql-tag'

export const getNavigation = gql`
  query GetNavigation($site: [String], $marketId: [QueryArgument]) {
    categoriesEntries(site: $site) {
      ... on categories_category_Entry {
        id
        slug
        key
        title
      }
    }
    globalSet(handle: "navigationHighlight") {
      ... on navigationHighlight_GlobalSet {
        id
        highlightContent(site: $site) {
          ... on highlightContent_highlight_BlockType {
            id
            highlightImage {
              id
              url @transform(format: "webp", width: 1000, height: 1260)
              width
              height
              format
              title
            }
            highlightTitle
            highlightUrl
            highlightUrlUsa
            highglightMarket(id: $marketId) {
              id
            }
          }
        }
      }
    }
  }
`
