import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const scrollSpeed = (el, binding) => {
  const speed = binding.value.factor
  const options = binding.value.options || {}

  gsap.to(el, {
    y: (i, target) => -1 * parseFloat(speed) * target.offsetHeight,
    ease: 'none',
    scrollTrigger: {
      trigger: el,
      invalidateOnRefresh: true,
      scrub: 0,
      ...options,
    },
  })
}
