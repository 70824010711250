import gql from 'graphql-tag'

export const listingNewsFragment = gql`
  fragment ListingNewsFragment on news_news_Entry {
    id
    slug
    title
    newsType
    postDate
    cover: newsCover @transform(format: "webp", width: 1000, height: 750) {
      id
      srcset(sizes: ["750", "1000"])
      url
      width
      height
      title
    }
  }
`

export const newsFragment = gql`
  fragment NewsFragment on news_news_Entry {
    id
    slug
    title
    newsType
    postDate
    market {
      slug
    }
    cover: newsCover @transform(format: "webp", width: 1920) {
      id
      srcset(sizes: ["750w", "1300w", "1920w"])
      url
      width
      height
      title
    }
    localized {
      slug
      language
    }
  }
`
