<template>
  <div>
    <NuxtLayout>
      <div class="page page-error">
        <div class="page__wrap" data-animate="page-wrap">
          <div class="row-1 p-top-3 p-bot-3">
            <header data-animate="header-title">
              <h1 data-animate="header-title" class="title-m">
                {{
                  error.statusCode === 500
                    ? $t('errors.500.title')
                    : $t('errors.404.title')
                }}
              </h1>
            </header>
            <div
              class="row-1 text-l m-top-6"
              data-animate="page-content"
              v-html="
                error.statusCode === 500
                  ? $t('errors.500.text')
                  : $t('errors.404.text')
              "
            />
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useCraftStore } from '@/store/craft.js'

const props = defineProps({
  error: Object,
})
console.error(props.error)

// Carico la configurazione del sito (mercati, linee, tipologie)
await useAsyncData(() => useCraftStore().fetchConfiguration())

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)
</script>
