import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'

export const getCatalogues = gql`
  ${seoFragment}
  query GetCatalogues($site: [String], $limit: Int = 100) {
    cataloguesEntries(site: $site) {
      ... on catalogues_catalogues_Entry {
        id
        title
        catalogues(limit: $limit) {
          ... on catalogues_catalogues_BlockType {
            id
            catalogueTitle
            catalogueFileUrl
            catalogueCover {
              id
            }
            catalogueCover
              @transform(format: "webp", width: 1000, height: 1500) {
              id
              srcset(sizes: ["750", "1000"])
              url
              width
              height
              title
            }
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`
