import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import {
  editorialContentDoubleColumnImages,
  editorialContentVideo,
  editorialContentGallery,
  editorialContentFullScreenGallery,
  editorialContentText,
  editorialContentBigTitle,
  editorialContentDoubleColumnGeneral,
  editorialContentFreeContent,
} from '../fragments/editorial.js'

export const getCompany = gql`
  ${seoFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  query GetCompany($site: [String]) {
    companyEntries(site: $site) {
      ... on company_company_Entry {
        id
        generalHeader {
          ... on generalHeader_generalHeader_BlockType {
            id
            generalHeaderText
            generalHeaderTitle
          }
        }
        editorialContent {
          id
          typeHandle
          ...EditorialContentText
          ...EditorialContentFullScreenGallery
          ...EditorialContentGallery
          ...EditorialContentVideo
          ...EditorialContentBigTitle
          ...EditorialContentDoubleColumnImages
          ...EditorialContentDoubleColumnGeneral
          ...EditorialContentFreeContent
        }
        generalCover @transform(format: "webp", width: 1920, height: 2400) {
          id
          srcset(sizes: ["750w", "1300w", "1920w"])
          url
          width
          height
          title
        }
      }
      seo {
        ...SeoFragment
      }
    }
  }
`

export const getResearch = gql`
  ${seoFragment}
  ${editorialContentText}
  ${editorialContentFullScreenGallery}
  ${editorialContentGallery}
  ${editorialContentVideo}
  ${editorialContentBigTitle}
  ${editorialContentDoubleColumnImages}
  ${editorialContentDoubleColumnGeneral}
  ${editorialContentFreeContent}
  query RetResearch($site: [String]) {
    researchEntries(site: $site) {
      ... on research_research_Entry {
        id
        generalHeader {
          ... on generalHeader_generalHeader_BlockType {
            id
            generalHeaderText
            generalHeaderTitle
          }
        }
        editorialContent {
          id
          typeHandle
          ...EditorialContentText
          ...EditorialContentFullScreenGallery
          ...EditorialContentGallery
          ...EditorialContentVideo
          ...EditorialContentBigTitle
          ...EditorialContentDoubleColumnImages
          ...EditorialContentDoubleColumnGeneral
          ...EditorialContentFreeContent
        }
        generalCover @transform(format: "webp", width: 1920, height: 2400) {
          id
          srcset(sizes: ["750w", "1300w", "1920w"])
          url
          width
          height
          title
        }
      }
      seo {
        ...SeoFragment
      }
    }
  }
`
