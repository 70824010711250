import gql from 'graphql-tag'

export const listingFinishingFragment = gql`
  fragment ListingFinishingFragment on finishings_finish_Entry {
    id
    title
    slug
    finishingColorLabel
    finishingColorCode
    finishingColorTexture {
      url(width: 600, height: 400, format: "webp")
    }
  }
`

export const finishingFragment = gql`
  fragment FinishingFragment on finishings_finish_Entry {
    id
    title
    slug
    finishingColorLabel
    finishingColorCode
    finishingColorTexture {
      url(width: 1000, height: 1000, format: "webp")
    }
    localized {
      slug
      language
    }
  }
`
