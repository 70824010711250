import gql from 'graphql-tag'
import {
  listingDesignerFragment,
  designerFragment,
} from '../fragments/designers.js'
import { listingProductFragment } from '../fragments/products.js'
import { seoFragment } from '../fragments/seo.js'

export const getDesigners = gql`
  ${listingDesignerFragment}
  ${seoFragment}
  query GetDesigners($site: [String], $limit: Int = 100, $offset: Int = 0) {
    designersEntries(site: $site, limit: $limit, offset: $offset) {
      ... on designers_designers_Entry {
        ...ListingDesignerFragment
      }
    }
    entryCount(site: $site, section: "designers")
    designersHomeEntries(site: $site) {
      ... on designersHome_designersHome_Entry {
        id
        generalHeader {
          ... on generalHeader_generalHeader_BlockType {
            id
            generalHeaderText
            generalHeaderTitle
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`

export const getDesigner = gql`
  ${designerFragment}
  ${listingProductFragment}
  ${seoFragment}
  query GetDesigner(
    $site: [String]
    $limit: Int = 100
    $offset: Int = 0
    $slug: [String]
    $marketId: [QueryArgument]
  ) {
    designersEntries(site: $site, slug: $slug) {
      ... on designers_designers_Entry {
        ...DesignerFragment
        seo {
          ...SeoFragment
        }
      }
    }
    productsEntries(
      site: $site
      market: $marketId
      relatedToEntries: { slug: $slug }
      limit: $limit
      offset: $offset
    ) {
      ... on products_product_Entry {
        ...ListingProductFragment
      }
    }
    entryCount(
      section: "products"
      site: $site
      market: $marketId
      relatedToEntries: { slug: $slug }
    )
  }
`
