import gql from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'

export const getDistributors = gql`
  ${seoFragment}
  query GetDistributors($site: [String]) {
    contactsEntries(site: $site) {
      ... on contacts_contacts_Entry {
        id
        subpageSeo {
          ... on subpageSeo_subpageSeo_BlockType {
            id
            subpageSeoMeta
            subpageSeoContent {
              ...SeoFragment
            }
          }
        }
      }
    }
    distributorsEntries(site: $site) {
      ... on distributors_distributors_Entry {
        id
        distributorsList {
          ... on distributorsList_distributorsList_BlockType {
            id
            distributorsAddress
            distributorsLatitude
            distributorsLongitude
            distributorsMeta
            distributorsName
            distributorsRegion
          }
        }
      }
    }
  }
`
