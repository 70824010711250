import SplitType from 'split-type'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export const onBeforeEnter = (el) => {
  const wrap = el.querySelector('[data-animate="page-wrap"]')
  gsap.set(wrap, { opacity: 0 })
}

export const onEnter = (el) => {
  const duration = 0.8

  const wrap = el.querySelector('[data-animate="page-wrap"]')

  const title = el.querySelector('[data-animate="header-title"]')
  const splitted = new SplitType(title, { types: 'words, lines' })
  const lines = splitted.lines
  const words = splitted.words
  gsap.set(lines, {
    overflow: 'hidden',
  })
  gsap.set(words, {
    y: '101%',
    skewY: 7,
    opacity: 0,
  })

  const list = el.querySelectorAll('[data-animate="list"] li')
  gsap.set(list, {
    y: '25%',
    opacity: 0,
  })

  const filters = el.querySelector('[data-animate="listing-filters"]')
  gsap.set(filters, {
    opacity: 0,
  })

  const content = el.querySelectorAll(
    '[data-animate="page-content"], [data-animate="header-content"]'
  )
  gsap.set(content, {
    opacity: 0,
  })

  const image = el.querySelector('[data-animate="header-image"]')
  gsap.set(image, {
    y: '10%',
    opacity: 0,
  })

  const tl = gsap.timeline({
    onComplete: () => {
      tl.kill()
      ScrollTrigger.refresh()
    },
  })

  tl.set(wrap, {
    opacity: 1,
  })
  tl.to(words, {
    y: '0%',
    skewY: 0,
    opacity: 1,
    ease: 'power4.out',
    stagger: 0.15,
    duration: duration,
  })
  tl.addLabel('start', '-=0.4')

  tl.to(
    list,
    {
      y: '0%',
      scale: 1,
      opacity: 1,
      ease: 'power4.out',
      stagger: 0.15,
      duration,
    },
    'start'
  )

  tl.to(
    image,
    {
      scale: 1,
      y: '0%',
      opacity: 1,
    },
    'start'
  )

  tl.to(
    filters,
    {
      opacity: 1,
      ease: 'power4.out',
      duration,
    },
    'start'
  )

  tl.to(
    content,
    {
      opacity: 1,
      duration,
    },
    'start'
  )

  return tl
}

export const onLeave = (el) => {
  const duration = 0.4
  const wrap = el.querySelector('[data-animate="page-wrap"]')
  const tl = gsap.timeline({
    onComplete: () => {
      tl.kill()
    },
  })
  tl.to(wrap, {
    opacity: 0,
    duration,
  })
  return tl
}
