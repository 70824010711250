import * as craft from './craft.js'
import * as navigation from './navigation.js'
import * as categories from './categories.js'
import * as typologies from './typologies.js'
import * as finishings from './finishings.js'
import * as designers from './designers.js'
import * as products from './products.js'
import * as news from './news.js'
import * as projects from './projects.js'
import * as company from './company.js'
import * as bespoke from './bespoke.js'
import * as catalogues from './catalogues.js'
import * as home from './home.js'
import * as search from './search.js'
import * as contacts from './contacts.js'
import * as distributors from './distributors.js'
import * as sitemap from './sitemap.js'

export default {
  craft,
  navigation,
  categories,
  typologies,
  finishings,
  designers,
  products,
  home,
  news,
  company,
  bespoke,
  catalogues,
  projects,
  search,
  contacts,
  distributors,
  sitemap,
}
