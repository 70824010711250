import gql from 'graphql-tag'

export const getSearch = gql`
  query GetSearch(
    $site: [String]
    $marketId: [QueryArgument]
    $search: String
    $limit: Int = 10
  ) {
    productsEntries(
      limit: $limit
      market: $marketId
      site: $site
      search: $search
    ) {
      ... on products_family_Entry {
        id
        slug
        title
        cover: productFamilyCover
          @transform(format: "webp", width: 800, height: 800) {
          id
          url
          width
          height
          title
        }
      }
      ... on products_product_Entry {
        id
        slug
        title
        cover: productCoverPortrait
          @transform(format: "webp", width: 800, height: 800) {
          id
          url
          width
          height
          title
        }
      }
    }
    projectsEntries(limit: $limit, site: $site, search: $search) {
      id
      slug
      title
      cover: projectCover @transform(format: "webp", width: 800, height: 800) {
        id
        url
        width
        height
        title
      }
    }
    newsEntries(
      limit: $limit
      market: $marketId
      site: $site
      search: $search
    ) {
      id
      slug
      title
      cover: newsCover @transform(format: "webp", width: 800, height: 800) {
        id
        url
        width
        height
        title
      }
    }
  }
`
