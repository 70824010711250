import gql from 'graphql-tag'

export const listingBespokeProjectsFragment = gql`
  fragment ListingBespokeProjectsFragment on bespoke_bespoke_Entry {
    id
    slug
    title
    projectType
    postDate
    cover: projectCover @transform(format: "webp", width: 1000, height: 750) {
      id
      srcset(sizes: ["1000"])
      url
      width
      height
      title
    }
  }
`

export const bespokeProjectsFragment = gql`
  fragment BespokeProjectsFragment on bespoke_bespoke_Entry {
    id
    slug
    title
    projectType
    cover: projectCover @transform(format: "webp", width: 1920) {
      id
      srcset(sizes: ["750w", "1300w", "1920w"])
      url
      width
      height
      title
    }
    localized {
      slug
      language
    }
  }
`
